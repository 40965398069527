declare var window: {
    dynamics: any;
} & typeof globalThis;

export class Dynamics {
    private readonly template: HTMLScriptElement;
    private readonly script: HTMLScriptElement;

    constructor() {
        this.template = document.querySelector('[data-supi-cookies="dynamics"]') as HTMLScriptElement;
        this.script = <HTMLScriptElement>document.createElement('script');
    }

    init(): void {
        const log = this.log;

        const contactFormIds = [
            'd37c66f6-1fb2-ef11-b8e8-7c1e52847265',
            'e140ab0f-20b2-ef11-b8e8-7c1e52847265',
            '93342822-20b2-ef11-b8e8-7c1e52847265',
            '8950d315-20b2-ef11-b8e8-7c1e52847265',
            '8e932028-20b2-ef11-b8e8-7c1e52847265',
            'eb01fe89-21b2-ef11-b8e8-000d3a44be56',
        ];
        const whitepaperFormIds = [
            '30dca9a5-20b2-ef11-b8e8-000d3a44be56',
            '958e04b9-20b2-ef11-b8e8-000d3a44be56',
            '9f5b13c6-20b2-ef11-b8e8-000d3a44be56',
            '14ebfcbe-20b2-ef11-b8e8-000d3a44be56',
            'cf06add3-20b2-ef11-b8e8-000d3a44be56',
            'e4e613a2-21b2-ef11-b8e8-000d3a44be56',
        ];
        const webinarFormIds = [
            'cf0f983d-5eef-ef11-9342-002248826c52',
            '538c4e52-5eef-ef11-9342-002248826c52',
            '33343f5e-5eef-ef11-9342-002248826c52',
            '6c083e6a-5eef-ef11-9342-002248826c52',
            'ed286376-5eef-ef11-9342-002248826c52',
            'ff6fc182-5eef-ef11-9342-002248826c52',
        ];
        const supportFormIds = [
            '60f6cc3b-21b2-ef11-b8e8-000d3a44be56',
            '33b28142-21b2-ef11-b8e8-000d3a44be56',
            '9b2c9555-21b2-ef11-b8e8-000d3a44be56',
            '5f699f49-21b2-ef11-b8e8-000d3a44be56',
            '52f3974f-21b2-ef11-b8e8-000d3a44be56',
            '23461796-21b2-ef11-b8e8-000d3a44be56',
        ];

        (window as any).initDynamics = () => {
            this.script.className = 'supi-scripts';
            this.script.async = true;
            this.script.dataset.supiCookies = this.template.dataset.supiCookies;
            this.script.innerHTML = this.template.innerHTML;
            this.template.parentNode?.replaceChild(this.script, this.template);
        };

        window.addEventListener('d365mkt-formrender', function (event) {
            log('Form rendered, try to move and remove invalid and useless elements');

            setTimeout(() => {
                const _head = document.getElementsByTagName('head');
                const _form = document.getElementById('mscrmform');
                _form?.querySelector('title')?.remove();
                _form?.querySelectorAll('meta[type="xrm/designer/setting"]')?.forEach((elem) => {
                    _head[0].append(elem);
                });
                _head[0].append(_form?.querySelector('style') as HTMLElement);
                _form?.querySelectorAll('meta')?.forEach((elem) => elem.remove());
            }, 500);

            setTimeout(function () {
                // check which form we are in to distinguish handling of hidden field styff
                // needs to consider multiple forms on a page, thus querying the current one.
                // @ts-ignore
                const formId = event.target?.getAttribute('data-form-id');
                const pageURL = window.location.href;

                // contact form
                if (formId && contactFormIds.indexOf(formId) > -1) {
                    document
                        .querySelectorAll(
                            '[data-form-id="' +
                                formId +
                                '"] [data-targetproperty="smp_mkt_subject"] input[name="smp_mkt_subject"]'
                        )
                        ?.forEach((elem) => {
                            // @ts-ignore
                            elem.defaultValue = pageURL;
                        });
                }

                // support form
                if (formId && supportFormIds.indexOf(formId) > -1) {
                    document
                        .querySelectorAll(
                            '[data-form-id="' +
                                formId +
                                '"] [data-targetproperty="smp_mkt_subject"] input[name="smp_mkt_subject"]'
                        )
                        ?.forEach((elem) => {
                            // @ts-ignore
                            elem.defaultValue = pageURL;
                        });
                }

                // whitepaper download
                if (formId && whitepaperFormIds.indexOf(formId) > -1) {
                    const whitepaperFile = document
                        .querySelector('div[data-whitepaper]')
                        ?.getAttribute('data-whitepaper');
                    if (whitepaperFile) {
                        document
                            .querySelectorAll(
                                '[data-form-id="' +
                                    formId +
                                    '"] [data-targetproperty="smp_gatedcontenturl"] input[name="smp_gatedcontenturl"]'
                            )
                            ?.forEach((elem) => {
                                // @ts-ignore
                                elem.defaultValue = whitepaperFile;
                            });
                    }
                    document
                        .querySelectorAll(
                            '[data-form-id="' +
                                formId +
                                '"] [data-targetproperty="smp_mkt_subject"] input[name="smp_mkt_subject"]'
                        )
                        ?.forEach((elem) => {
                            // @ts-ignore
                            elem.defaultValue = pageURL;
                        });
                }

                // webinar form
                if (formId && webinarFormIds.indexOf(formId) > -1) {
                    const webinarUrl = document.querySelector('div[data-webinar]')?.getAttribute('data-webinar');
                    if (webinarUrl) {
                        document
                            .querySelectorAll(
                                '[data-form-id="' +
                                    formId +
                                    '"] [data-targetproperty="smp_gatedcontenturl"] input[name="smp_gatedcontenturl"]'
                            )
                            ?.forEach((elem) => {
                                // @ts-ignore
                                elem.defaultValue = webinarUrl;
                            });
                    }
                    document
                        .querySelectorAll(
                            '[data-form-id="' +
                                formId +
                                '"] [data-targetproperty="smp_mkt_subject"] input[name="smp_mkt_subject"]'
                        )
                        ?.forEach((elem) => {
                            // @ts-ignore
                            elem.defaultValue = pageURL;
                        });
                }
            }, 200);
        });

        window.addEventListener('d365mkt-afterformsubmit', function (event: any) {
            if (event.detail.successful === true) {
                log('Form submitted successfully');

                // @ts-ignore
                const formId =
                    event.target?.getAttribute('data-form-id') ||
                    event.target?.parentNode?.getAttribute('data-form-id') ||
                    event.target?.parentNode?.parentNode?.getAttribute('data-form-id');
                if (formId && contactFormIds.indexOf(formId) > -1) {
                    // @ts-ignore
                    const dataLayer = (window.dataLayer = window.dataLayer || []);
                    dataLayer.push({ event: 'main_form_submit' });

                    // @ts-ignore
                    const _paq = (window._paq = window._paq || []);
                    _paq.push(['trackEvent', 'Form', 'main_form_submit']);

                    // @ts-ignore
                    if (window.lintrk) {
                        // @ts-ignore
                        window.lintrk('track', { conversion_id: 20375538 });
                    }
                }
                if (formId && whitepaperFormIds.indexOf(formId) > -1) {
                    // @ts-ignore
                    const dataLayer = (window.dataLayer = window.dataLayer || []);
                    dataLayer.push({ event: 'whitepaper_form_submit' });

                    // @ts-ignore
                    const _paq = (window._paq = window._paq || []);
                    _paq.push(['trackEvent', 'Form', 'whitepaper_form_submit']);

                    // @ts-ignore
                    if (window.lintrk) {
                        // @ts-ignore
                        window.lintrk('track', { conversion_id: 20375546 });
                    }
                }
                if (formId && webinarFormIds.indexOf(formId) > -1) {
                    // @ts-ignore
                    const dataLayer = (window.dataLayer = window.dataLayer || []);
                    dataLayer.push({ event: 'webinar_form_submit' });

                    // @ts-ignore
                    const _paq = (window._paq = window._paq || []);
                    _paq.push(['trackEvent', 'Form', 'webinar_form_submit']);
                }
                if (formId && supportFormIds.indexOf(formId) > -1) {
                    // @ts-ignore
                    const dataLayer = (window.dataLayer = window.dataLayer || []);
                    dataLayer.push({ event: 'support_form_submit' });

                    // @ts-ignore
                    const _paq = (window._paq = window._paq || []);
                    _paq.push(['trackEvent', 'Form', 'support_form_submit']);
                }

                setTimeout(() => {
                    const successElem: NodeListOf<HTMLElement> = document.querySelectorAll('.onFormSubmittedFeedback');

                    successElem.forEach((elem) => {
                        elem.style.height = 'auto !important';
                    }, 500);
                });
            }
        });

        window.addEventListener('serviceCallback', ((e) => {
            log('dynamics service callback');
            const consentInfo = (e.target as HTMLElement).querySelector('.consent') as HTMLElement;
            consentInfo?.remove();

            const form = (e.target as HTMLElement).querySelector('[data-form-id]') as HTMLElement;
            form?.classList.remove('d-none');
        }) as EventListener);
    }

    private log(message: any): void {
        const body = document.querySelector('body') as HTMLBodyElement;
        if (body?.classList.contains('develop')) {
            console.log(message);
        }
    }
}
