import 'img-comparison-slider';

export class Scrubber {
    // Tracks the previous exposure percentage to determine if the exposure has changed.
    private static previousExposure = 50;

    // Current opacity levels for the first and second caption elements.
    private static currentCaption1 = 50;
    private static currentCaption2 = 50;

    // Define minimum and maximum limits for the caption opacity percentages.
    private static readonly MIN_CAPTION = 10;
    private static readonly MAX_CAPTION = 90;

    /**
     * Initializes the Scrubber functionality by setting up event handlers.
     * It binds the `slide` event of the `img-comparison-slider` component
     * to a custom event handler for dynamic updates.
     */
    static init() {
        const safariElem = document.querySelector('.scrubber-safari');
        const otherElem = document.querySelector('.scrubber-safari + .scrubber');

        let scrubberWrapper;
        let scrubber;

        if (this.isSafari()) {
            otherElem?.remove();
            safariElem?.classList.remove('d-none');

            scrubberWrapper = safariElem?.parentElement;
            scrubber = safariElem;
        } else {
            safariElem?.remove();
            otherElem?.classList.remove('d-none');

            scrubberWrapper = otherElem?.parentElement;
            scrubber = otherElem;
        }

        // Find the first and last caption elements inside the scrubber wrapper.
        const figCaption = scrubberWrapper?.querySelector('figcaption') as HTMLElement;

        // Attach the `slide` event listener to the slider, passing the captions to the handler.
        scrubber?.addEventListener('slide', this.handleSlideEvent.bind(this, figCaption));
    }

    /**
     * Handles the `slide` event triggered by changes in the image comparison slider's position.
     * Adjusts caption opacity based on the slider's current exposure value.
     *
     * @param figcaption
     * @param e - The event object associated with the `slide` event.
     */
    private static handleSlideEvent(figcaption: HTMLSpanElement, e: Event): void {
        // Safely obtain the first child element (slider handle) of the slider.
        // @ts-ignore
        const sliderElement = (e.target as HTMLElement)?.firstElement as HTMLElement;
        if (!sliderElement) {
            // Exit if the slider element is not properly found.
            return;
        }

        // Get the computed styles of the slider element to retrieve the exposure variable.
        const styles = window.getComputedStyle(sliderElement);
        const computedExposure = parseInt(styles.getPropertyValue('--exposure').trim(), 10);

        // Round the computed exposure value to the nearest multiple of 10 for optimization.
        const roundedExposure = Math.round(computedExposure / 10) * 10;

        // Exit the function if the exposure value has not changed since the last slide event.
        if (roundedExposure === this.previousExposure) {
            return;
        }

        // Calculate the difference between the new and previous exposures.
        const exposureDifference = roundedExposure - this.previousExposure;

        // Update the caption opacity values within the defined MIN and MAX constraints.
        this.currentCaption1 = Math.min(
            this.MAX_CAPTION,
            Math.max(this.MIN_CAPTION, this.currentCaption1 + exposureDifference)
        );
        this.currentCaption2 = 100 - this.currentCaption1;

        // Update the previous exposure to the new value for future calculations.
        this.previousExposure = roundedExposure;

        // Dynamically update the opacity CSS variable for the captions.
        const caption1 = figcaption?.querySelector('.label span:first-child') as HTMLSpanElement;
        const caption2 = figcaption?.querySelector('.label span:last-child') as HTMLSpanElement;

        caption1?.style.setProperty('--scrubber-opacity', `${this.currentCaption2}%`);
        caption2?.style.setProperty('--scrubber-opacity', `${this.currentCaption1}%`);

        // updates the text for visually hidden elements
        const val1 = figcaption?.querySelector('.scrubber-progress span:first-child') as HTMLSpanElement;
        const val2 = figcaption?.querySelector('.scrubber-progress span:last-child') as HTMLSpanElement;

        val1.textContent = this.currentCaption1 + '%';
        val2.textContent = this.currentCaption2 + '%';
    }

    private static isSafari() {
        const ua = navigator.userAgent;
        return ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('Chromium');
    }
}
